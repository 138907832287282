import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import store from './store/index'
import './assets/scss/reset.scss'
import './H5Size'
import core from './core/index'
import {
    Tab, Tabs, Button, Loading, PullRefresh, List, Cell, CellGroup, RadioGroup, Radio, Image, Uploader, Toast, Field,
    Swipe, SwipeItem, Collapse, CollapseItem, Icon, Popup, Picker, Lazyload
} from 'vant';

Vue.use(Tab).use(Tabs);
Vue.use(Loading);
Vue.use(Button);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Cell).use(CellGroup);
Vue.use(Image);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Swipe).use(SwipeItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Icon);
Vue.use(Collapse).use(CollapseItem);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Lazyload);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
core.cookie.removeStorage('sessionId')
// router.beforeEach((to, from, next) => {
//     debugger;
//     if (!core.cookie.getStorage('sessionId') && (to.name !== 'Rules' && to.name !== 'Home' && to.name !== 'Login' && to.name)) {
//         next({path: '/login', query: {redirect: to.fullPath}})
//     } else {
//         next()
//     }a
// })
router.beforeResolve((to, from, next)=> {
    if (!core.cookie.getStorage('sessionId') && (to.name !== 'Rules' && to.name !== 'Home' && to.name !== 'Login' && to.name !='voteIndex' && to.name !=='voteForm' && to.name)) {
        next({path: '/login', query: {redirect: to.fullPath}})
        // next({path: '/login', query: {redirect: to.fullPath}})
    } else {
        next()
    }
})
router.afterEach(() => {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
})
