function getStorage(key) {
    // if (process.env.NODE_ENV === 'development') return 'aef1b1a4-e827-4b24-af6b-fe2fbfffe391'
    if (process.env.NODE_ENV === 'development') return '0b4734e7-50e4-40ec-bfc2-12d81c619f69'
    return window.localStorage.getItem(key)
}

function setStorage(key, value) {
    window.localStorage.setItem(key, value)
}

function removeStorage(key) {
    window.localStorage.removeItem(key)
}

export default {
    getStorage,
    setStorage,
    removeStorage
}