import axios from 'axios';

//构建参数字符串
function CreateParamStr(param) {
  var paramStr = "";
  if (param != undefined) {
      var jsonLength = 0;
      for (var key in param) {
          if (param[key] == undefined) {
              paramStr += key + '=null&';
          }
          else {
              paramStr += key + '=' + param[key] + '&';
          }
          jsonLength++;
      }
      if (jsonLength != 0) {
          paramStr = paramStr.substring(0, paramStr.length - 1);
      }
  }
  return paramStr;
}
/**
 * 模块说明:有api_token的请求
 */
export const request = ({url, method = 'GET', normal, params = {}}) => {
  const data = (method === 'GET') ? 'params' : 'data';
  let headers = {
    "Accept": "application/json",
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
  };
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      [data]: (method === 'GET' || normal) ? params : CreateParamStr(params),
      headers,
    }).then(data => {
      resolve(data.data)
    }).catch(error => {
        // console.dir(error);
        sessionStorage.removeItem('params')
        // Message.error(typeof error.response.data === 'string' ? error.response.data : JSON.stringify(error.response.data));
        reject(error);
      });
  });
};