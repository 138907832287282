<template>
    <div class="views-home">
        <!--                <div class="" style="text-align: center;padding-top: 3rem;font-size: 2rem;">七月一日正式启动</div>-->
        <div class="content">
            <div class="imgs">
                <p v-for="(item, index) in imgList" :key="index" :class="index == 6 ? 'left':index == 7?'right':''">
                    <img :src="getItemImg(index)" alt=""/>
                </p>
            </div>
            <div class="link-content">
                <div class="link-content-right">
                    <a href="javascript:" @click="toUrl('apply')" class="to-apply"></a>
                    <a href="javascript:" @click="toUrl('list')" class="to-list"></a>
                    <a href="javascript:" @click="toUrl('rules')" class="to-rules"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Toast} from 'vant'
    import getStrParams from "../utils/getStrParams";
    import initWXAPI from "../utils/initWXAPI";
    import WXShare from "../utils/WXShare";

    export default {
        name: 'Home',
        components: {},
        data() {
            return {
                imgList: new Array(12),
                bottomImgList: new Array(3)
            }
        },
        methods: {
            getItemImg(index) {
                return require(`../static/image/home/index_${index >= 9 ? (index + 1) : '0' + (index + 1)}.png`)
            },
            getBottomImg(index) {
                return require(`../static/image/home/hsgs_new_${index + 11}.png`)
            },
            toUrl(type) {
                // if (type == 'apply' || type == 'list') {
                //     Toast('七月十日开放，敬请期待！')
                //     return;
                // }
                if (type === 'apply') {
                    window.location.href = 'https://mp.weixin.qq.com/s/9_PQ67gFGmJjX-hIBnh43w';
                    return;
                }
                this.$router.push({path: type}).catch(() => {
                })
            },
            initPageShare() {
                initWXAPI.init(this.$store.state.url)
                const options = {
                    title: '红色故事演讲大赛',
                    desc: '2022上海市民文化节',
                    imgUrl: 'https://redsw1.02lu.com/share_logo.png',
                    link: window.location.href
                }
                WXShare.initWXShare(options)
                WXShare.initWXTimelineShare(options)
            }
        },
        created() {
            // this.activityType = getStrParams.getStrParams(window.location.href.split('?')[1]).type
            this.initPageShare()
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/index";

    .views-home {
        position: relative;
        min-height: 100vh;
        background: #be0b15;

        .imgs {
            font-size: 0;
            width: 100%;

            .left {
                width: 40%;
                display: inline-block
            }

            .right {
                width: 60%;
                display: inline-block
            }

            img {
                width: 100%;
            }
        }

        .link-content {
            display: flex;
            font-size: 0;

            .link-content-right {
                a {
                    position: absolute;
                    background: transparent;
                    /*background: red;*/
                    width: toRem(130);
                    height: toRem(130);

                    &.to-apply {
                        top: toRem(680);
                        left: toRem(100);
                    }

                    &.to-list {
                        top: toRem(680);
                        left: toRem(280);
                    }

                    &.to-rules {
                        top: toRem(410);
                        left: toRem(510);
                    }
                }
            }
        }
    }
</style>


