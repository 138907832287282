import { request } from './api'

/**
 * 获取微信配置
 */
export const getWXConfig = (params) => {
  return request({
    url: `/apis/Wx.weixin.getjsapi_signature`,
    method: 'POST',
    normal: true,
    params
  })
}



/**
 * 我的作品列表
 */
export const myWorksList = (params) => {
  return request({
    url: `/apis/Wx.SignUpFile.MyList`,
    method: 'POST',
    normal: true,
    params
  })
}

/**
 * 我的点赞列表
 */
export const myVoteLogList = (params) => {
  return request({
    url: `/apis/Wx.Vote.GetMyVoteLogList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 排行榜
 */
export const rankingList = (params) => {
  return request({
    url: `/apis/Wx.Vote.PH`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 微信登录
 */
export const userLogin = (params) => {
  return request({
    url: `/apis/account.WeiXinLogIn`,
    method: 'POST',
    normal: true,
    params
  })
}



/**
 * 获取区列表
 */
export const getArea = (params) => {
  return request({
    url: `/apis/Wx.Base.GetDistrictList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 获取区下面的街道列表
 */
export const getStreet = (params) => {
  return request({
    url: `/apis/Wx.Base.GetSubDistrictList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 报名
 */
export const applyActivity = (params) => {
  return request({
    url: `/apis/Wx.BaoMing.SignUps`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 创建视频Id
 */
export const videoCreate = (params) => {
  return request({
    url: `/apis/Wx.VodCreate`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 创建视频Id
 */
export const indexPageList = (params) => {
  return request({
    url: `/apis/Wx.VoteIndex.VoteIndexPageList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 上传微信图片
 */
export const uploadWXImage = (params) => {
  return request({
    url: `/apis/Wx.PhotoWXMedia`,
    method: 'POST',
    normal: true,
    params
  })
}



/**
 * 上传微信图片
 */
export const createWorks = (params) => {
  return request({
    url: `/apis/Wx.SignUpFile.Create`,
    method: 'POST',
    normal: true,
    params
  })
}



/**
 * 获取用户报名状态
 */
export const hasUpFileInfo = (params) => {
  return request({
    url: `/apis/Wx.SignUpFile.HasUpFileInfo`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 获取用户信息
 */
export const userInfo = (params) => {
  return request({
    url: `/apis/Wx.GetAccount`,
    method: 'POST',
    normal: true,
    params
  })
}



/**
 * 获取作品详情
 */
export const worksInfo = (params) => {
  return request({
    url: `/apis/Wx.SignUpFile.Info`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 获取作品列表
 */
export const votePageList = (params) => {
  return request({
    url: `/apis/Wx.VoteIndex.VoteIndexPageList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 点赞作品
 */
export const voteOne = (params) => {
  return request({
    url: `/apis/Wx.Vote.VoteOne`,
    method: 'POST',
    normal: true,
    params
  })
}





/**
 * 投票
 */
export const userVoteApi = (params) => {
  return request({
    url: `/apis/Wx.Zjcc.UserVote`,
    method: 'POST',
    normal: true,
    params
  })
}





/**
 * 用户投票记录
 */
export const voteHistoryApi = (params) => {
  return request({
    url: `/apis/Wx.Zjcc.GetMyVoteLogs`,
    method: 'POST',
    normal: true,
    params
  })
}







