import wx from "weixin-js-sdk";
import getStrParams from "./getStrParams";
import initWXAPI from "./initWXAPI";
import store from "../store/index"

export default {
    getDefaultShare(url) {
        let type = '1'
        const urlParams = getStrParams.getStrParams(url)
        if (urlParams && urlParams.type) type = urlParams.type
        return `https://redsw1.02lu.com/`
    },
    initWXShare(options) {
        const self = this
        wx.ready(function () {
            wx.updateAppMessageShareData({
                title: options.title || '红色故事演讲大赛', // 分享标题
                desc: options.desc || '2022上海市民文化节', // 分享描述
                link: options.link || self.getDefaultShare(options.link), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: options.imgUrl || 'https://redsw1.02lu.com/share_logo.png', // 分享图标
                success: function () {
                }
            })
        })
    },
    initWXTimelineShare(options) {
        const self = this
        wx.ready(function () {
            wx.updateTimelineShareData({
                title: options.title || '红色故事演讲大赛', // 分享标题
                link: options.link || self.getDefaultShare(options.link), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: options.imgUrl || 'https://redsw1.02lu.com/share_logo.png', // 分享图标
                success: function () {
                }
            })
        })
    },
    localShare() {
        initWXAPI.init(store.state.url)
        const options = {
            title: '红色故事演讲大赛',
            desc:  '2022上海市民文化节',
            imgUrl: 'https://redsw1.02lu.com/share_logo.png',
            link: this.getDefaultShare(store.state.url)
        }
        this.initWXShare(options)
        this.initWXTimelineShare(options)
    }
}