<template>
    <div id="app">
<!--        <div class="" style="text-align: center;margin-top: 3rem;font-size: 2rem;">七月一日正式启动</div>-->
        <router-view>
            <Home></Home>
        </router-view>
    </div>
</template>
<script>
    import Home from './views/Home'

    export default {
        name: 'App',
        components: {
            Home
        },
        data() {
            return {}
        }
    }
</script>
