import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Home1 from '../views/Home1.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/home1',
    name: 'Home1',
    component: Home1
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import('../views/Apply.vue')
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue')
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('../views/Rules.vue')
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/rankingList',
    name: 'RankingList',
    component: () => import('../views/RankingList.vue')
  },
  {
    path: '/uploadWorks',
    name: 'UploadWorks',
    component: () => import('../views/UploadWorks.vue')
  },
  {
    path: '/userCenter',
    name: 'UserCenter',
    component: () => import('../views/UserCenter.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/sharePage',
    name: 'SharePage',
    component: () => import('../views/SharePage.vue')
  },
  {
    path: '/voteIndex',
    name: 'voteIndex',
    component: () => import('../views/vote/Index.vue')
  },
  {
    path: '/voteFrom',
    name: 'voteForm',
    component: () => import('../views/vote/Form.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
