export default {
    getStrParams(str) {
        if(str) {
            const nObject = {};
            const list = str.split('&')
            list.forEach(d=>{
                nObject[d.split('=')[0]] = d.split('=')[1]
            })
            return nObject
        }
    }
}