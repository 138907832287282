import wx from 'weixin-js-sdk'
import {getWXConfig} from "../server";


export default {
    init(url, callback) {
        let _url = /iPhone|mac|iPod|iPad/i.test(navigator.userAgent) ? url : window.location.href.split('#')[0];
        (async function () {
            const data = await getWXConfig({url: encodeURIComponent(_url)})
            if (data && data.Data) {
                const _data = data.Data;
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: _data.appid, // 必填，公众号的唯一标识
                    timestamp: _data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: _data.nonceStr, // 必填，生成签名的随机串
                    signature: _data.signature,// 必填，签名
                    jsApiList: [// 必填，需要使用的JS接口列表
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'chooseImage',
                        'uploadImage'
                    ]
                });
                if (callback) {
                    wx.ready(function () {
                        callback();
                    })
                }
            }
        })()
    }
}